import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import * as S from './styles'

export const CompanyInformation = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulCompanyDetails {
          nodes {
            text
            title
            icon {
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const details = data.allContentfulCompanyDetails.nodes

      return (
        <S.Wrapper as='section'>
          {details.map(({ icon: { file }, title, text }) => (
            <S.Article key={title}>
              <S.Image src={file.url} alt={`Ikona ${title}`} />
              <S.Heading as='h2' h2>
                {title}
              </S.Heading>
              <S.Paragraph>{text}</S.Paragraph>
            </S.Article>
          ))}
        </S.Wrapper>
      )
    }}
  />
)
