import styled from 'styled-components'

import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'
import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'

import { device } from 'src/themes/breakpoints'

import mapMobile from 'src/images/map-mobile.png'
import mapDesktop from 'src/images/map-desktop.png'
import mapDesktopLarge from 'src/images/map-desktop-large.png'

export const Section = styled.section`
  background: url(${mapMobile}) no-repeat center center / cover;
  padding: 8rem 0 3rem;
  position: relative;

  @media ${device.mobileXl} {
    background-image: url(${mapDesktop});
  }

  @media ${device.tablet} {
    padding: 25rem 0 13rem;
  }

  @media ${device.laptopL} {
    background-image: url(${mapDesktopLarge});
  }
`

export const Wrapper = styled(WrapperComponent)`
  max-width: 75rem;
`

export const Heading = styled(HeadingComponent)`
  text-align: right;

  @media ${device.mobileXl} {
    text-align: center;
    margin-bottom: 7rem;
  }
`

export const Paragraph = styled(ParagraphComponent)`
  text-align: right;
  margin-bottom: 3rem;

  @media ${device.mobileXl} {
    display: none;
  }
`
