import styled, { css } from 'styled-components'
import { Form as FormikForm, Field, ErrorMessage as FormikErrorMessage } from 'formik'

import { Button as ButtonComponent } from 'src/components/common/button/Button'
import { device } from 'src/themes/breakpoints'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileXl} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Row = styled.label`
  position: relative;
  width: 100%;

  @media ${device.mobileXl} {
    :nth-of-type(1) {
      margin-right: 10%;
    }

    :nth-of-type(1),
    :nth-of-type(2) {
      flex-basis: 45%;
    }
  }
`

export const Input = styled(Field)`
  padding: 0.4rem 0;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ theme }) => theme.fonts.size.s};
  color: ${({ theme }) => theme.colors.fontGray};
  width: 100%;
  border: none;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.fontGray};
  margin-bottom: 3rem;
  background: transparent;

  @media ${device.mobileXl} {
    font-size: ${({ theme }) => theme.fonts.size.m};
  }
`

export const ErrorMessage = styled(FormikErrorMessage)`
  position: absolute;
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  color: ${({ theme }) => theme.colors.btnRed};
  bottom: 1.5rem;
`

export const Button = styled(ButtonComponent)`
  border: none;

  @media ${device.mobileXl} {
    align-self: center;
  }

  ${({ page }) =>
    page === 'contact' &&
    css`
      margin-top: 6rem;
    `}
`
