import styled from 'styled-components'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'

import { device } from 'src/themes/breakpoints'

export const Wrapper = styled(WrapperComponent)`
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media ${device.tablet} {
    max-width: 90rem;
    display: flex;
    justify-content: space-between;
  }
`

export const Article = styled.article`
  text-align: center;
  margin-bottom: 5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Image = styled.img`
  @media ${device.tablet} {
    height: 50px;
  }

  @media ${device.laptop} {
    height: 60px;
  }
`

export const Heading = styled(HeadingComponent)`
  margin: 3rem 0 2rem;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fonts.size.l};
  }

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fonts.size.xxl};
  }
`

export const Paragraph = styled(ParagraphComponent)`
  margin: 0 auto;
  display: block;
  width: 220px;
`
