import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import * as S from './styles'

export const Form = ({ page }) => (
  <Formik
    initialValues={{ email: '', name: '', message: '' }}
    validate={values => {
      const errors = {}
      if (!values.email) {
        errors.email = 'To pole jest wymagane!'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Nieprawidłowy adres email'
      }

      if (!values.name) errors.name = 'To pole jest wymagane!'

      if (!values.message) errors.message = 'Wiadomość jest za krótka!'

      return errors
    }}
    onSubmit={(values, { setSubmitting }) => {
      setTimeout(() => {
        // alert(JSON.stringify(values, null, 2))
        setSubmitting(false)
      }, 400)
    }}
  >
    {({ isSubmitting }) => (
      <S.Form>
        <S.Row>
          <S.Input type='email' name='email' placeholder='Email' />
          <S.ErrorMessage name='email' component='div' />
        </S.Row>
        <S.Row>
          <S.Input type='text' name='name' placeholder='Imię' />
          <S.ErrorMessage name='name' component='div' />
        </S.Row>
        <S.Row>
          <S.Input name='message' component='textarea' rows='3' placeholder='Wiadomość' />
          <S.ErrorMessage name='message' component='div' />
        </S.Row>
        <S.Button as='button' type='submit' disabled={isSubmitting} page={page}>
          Wyślij &gt;
        </S.Button>
      </S.Form>
    )}
  </Formik>
)

Form.propTypes = { page: PropTypes.string.isRequired }
