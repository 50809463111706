import React from 'react'

import { MainTemplate } from 'src/components/templates/MainTemplate'
import { Form } from 'src/components/contact/form/Form'
import { CompanyInformation } from 'src/components/contact/companyInformation/CompanyInformation'

import SEO from '../components/seo/seo'

const ContactPage = () => (
  <MainTemplate>
    <SEO description='dsafagaeggr' title='Porozmawiajmy | Kuliberda.tech' />
    <Form />
    <CompanyInformation />
  </MainTemplate>
)

export default ContactPage
