import React from 'react'

import { Form as FormComponent } from 'src/components/common/form/Form'

import * as S from './styles'

export const Form = () => (
  <S.Section>
    <S.Wrapper>
      <S.Heading>Wyślij wiadomość</S.Heading>
      <S.Paragraph>Upewnij się, żepodajesz poprawnego maila</S.Paragraph>
      <FormComponent page='contact' />
    </S.Wrapper>
  </S.Section>
)
